<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} ERC<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="item.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">Active <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to types of personnel list"
              @click="$router.push({name: 'admin.erc.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Name</label>
                          <input
                            v-model="item.name"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Acronym</label>
                          <input
                            v-model="item.code"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Parent</label>
                          <v-select
                            v-model="item.parent"
                            label="name"
                            :options="parentLevels"
                            :selectable="(option) => option.id && option.id != item.id"
                            :get-option-key="option => option.id"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      itemId: this.$route.params.id,
      sending: false,
      errors: '',
      test: [],
    }
  },
  computed: {
    ...mapGetters({
      item: 'erc/item',
      ercs: 'erc/items',
      ercsNotVisibles: 'erc/itemsNotVisibles',
      parentLevels: 'erc/parentLevels',
    }),
  },
  async mounted() {
    if (this.itemId) {
      await this.$store.dispatch('erc/fetchId', this.itemId)
    } else {
      await this.$store.dispatch('erc/cleanItem')
      this.item.active = true
    }

    await this.$store.dispatch('erc/filter', { parentLevels: 'yes' })
    await this.$store.dispatch('erc/fetchNotVisible')
    await this.$store.dispatch('erc/getParentLevels')
  },
  methods: {
    async save() {
      this.sending = true
      try {
        if (this.itemId) {
          await this.$store.dispatch('erc/update', { id: this.itemId, data: this.item })
        } else {
          await this.$store.dispatch('erc/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The erc has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.erc.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
  },

}
</script>
